<template>
    <div class="modal fade" id="modalOrdenesA" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Historial de Ordenes</h5>
                    <button type="button" class="close" @click="closeModal()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="alert alert-warning mt-2" v-if="!pagination.data.length">
                        Sin Ordenes anteriores...
                    </div>
                    <data-grid :data="pagination" @changePage="changePage($event)"
                        @perPageChange="perPageChange($event)" @search="search($event)" :searchBox="false">
                        <template #grid>
                            <div class="card mt-2 border-gray-500 shadow-none mb-1"
                                v-for="(item) in pagination.data" :key="item.id">
                                
                                    <div class="card-header bg-light">
                                        <div class="row">
                                            <div class="col-4 ">
                                                Orden No. {{ item.id }}
                                            </div>
                                            <div class="col-8 d-flex justify-content-end">
                                                <span class="text-danger mx-2">{{ item.historia.user.name }}</span>
                                                ({{ item.created_at | formatDateTime }})
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <ul class="col-md-8 col-sm-12 col-lg-12 font-weight-bold">
                                                <li  v-for="procedimiento in item.items" :key="procedimiento.id">
                                                    {{ procedimiento.servicio.codigo }}
                                                    {{ procedimiento.servicio.descripcion }}
                                                    <div class="small text-muted d-none d-md-block">
                                                        Observación: {{ procedimiento.observacion }}
                                                    </div>
                                                    <hr>
                                                </li>
                                            </ul>
                                            <div class="col-md-8 col-sm-12 col-lg-12">
                                                Observaciones Generales: <span
                                                    class="text-primary">{{ item.observaciones }}</span>
                                            </div>
                                        </div>
                                        <div class="btn-group shadow-sm float-right" role="group">
                                            <!-- <button @click="verOrden(item.id)" class="btn btn-outline-primary btn-xs">
                                                <i class="fa fa-eye fa-fw"></i> Ver orden
                                            </button> -->
                                            <button @click="imprimir(item.id)" class="btn btn-outline-success btn-xs">
                                                <i class="fa fa-print fa-fw"></i> Imprimir
                                            </button>
                                        </div>
                                    </div>
                                
                            </div>
                        </template>
                    </data-grid>
                </div>
                <div class="modal-footer">

                    <button type="button" class="btn btn-light btn-sm" @click="closeModal()"><i
                            class="far fa-times-circle fa-fw"></i>
                        Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import ordenClinicaService from '../../../../services/ordenClinicaService';
import DataGrid from "../../../../components/common/utilities/DataGrid/DataGrid";
//import {required } from "vuelidate/lib/validators";
import Toast from '../../../../components/common/utilities/toast';
import historiaClinicaService from '../../../../services/historiaClinicaService';
export default {
    props: ['idHistoria', 'usuario'],
    components: { DataGrid },
    data() {
        return {
            idUsuario: '',
            pagination: {
                data: [],
                current_page: 1,
                page: 1,
                per_page: 5,
                search: '',
                total: '',
                params: {
                    id_user: "",
                    fecha: "",
                },
            },

        }
    },
    methods: {
        async verOrden(id) {
            $('#modalOrden').modal('hide');
            this.$router.push({ name: 'orden.clinica', params: { 'id_orden': id, 'id_usuario': this.idUsuario }});
        },
        imprimir(id_orden) {
            window.open(historiaClinicaService.orden(id_orden));
        },
        async openModal(idUsuario) {
            $('#modalOrdenesA').modal('show');
            this.idUsuario = idUsuario;
            try {
                this.LoaderSpinnerShow();

                const response = (await ordenClinicaService.getAll(this.pagination.search, this.pagination, idUsuario))?.data;

                this.pagination.data = response.data.map(x => {
                    return {
                        id: x.id,
                        historia: x.historia,
                        created_at: x.created_at,
                        observaciones: x.observaciones,
                        items: x.items.map(item => {
                            return {
                                id: item.id,
                                servicio: item.servicio,
                                observacion: item.observacion
                            }
                        }),
                    }
                });
                this.pagination.current_page = response.current_page;
                this.pagination.total = response.total;
                this.LoaderSpinnerHide();
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }

        },
        closeModal() {
            $('#modalOrdenesA').modal('hide');
        },
        changePage(page) {
            this.pagination.page = page;
            this.cargar(this.idUsuario);
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.cargar(this.idUsuario);
        },
        search(query) {
            this.pagination.search = query;
            this.pagination.page = 1;
            this.cargar(this.idUsuario);
        },
        async cargar(idUsuario) {
            try {
                this.LoaderSpinnerShow();
                const response = (await ordenClinicaService.getAll(this.pagination.search, this.pagination, idUsuario))?.data;

                this.pagination.data = response.data.map(x => {
                    return {
                        id: x.id,
                        historia: x.historia,
                        created_at: x.created_at,
                        observaciones: x.observaciones,
                        items: x.items.map(item => {
                            return {
                                id: item.id,
                                servicio: item.servicio,
                                observacion: item.observacion
                            }
                        }),
                    }
                });
                this.pagination.current_page = response.current_page;
                this.pagination.total = response.total;
                this.LoaderSpinnerHide();
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        },

    },

}
</script>
