<template>
    <main>
        <div class=" ">
            <div class="card-header bg-light "><span><i class="fa fa-syringe"> </i> Vacunas</span> </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered table-sm">
                        <tr >
                            <th>Vacuna</th>
                            <th>Dosis</th>
                            <th>Fecha</th>
                            <th>Observacion</th>
                            <th>Institucional</th>
                        </tr>
                        <tr v-for="item in items" :key="item.id" >
                            <td>{{item.vacuna.nombre}}</td>
                            <td>{{item.dosis.nombre_dosis}}</td>
                            <td>{{ item.fecha }}</td>
                            <td v-if="item.observacion===null">
                                <span >Sin observacion</span>
                            </td>
                            <td v-else>{{ item.observacion }}</td>
                            <td>{{ item.institucional }}</td>
                        </tr>                                
                    </table>
                </div>
            </div>
         </div>
    </main>
</template>
<script>
import {isEmpty} from "lodash";
export default {
    props: ['idHistoria','finalizada','data'],
    data() {
        return {
            items: [],
        };
    },
   
    async created() {
        if(!isEmpty(this.data)){
            this.items = this.data.vacunas.map(x=>{
                return {
                    id:x.id,
                    fecha:x.fecha,
                    observacion:x.observaciones,
                    institucional:x.institucional,
                    vacuna:x.dosis.vacuna,
                    dosis:x.dosis
                }
            });          
        }
    },
   

}
</script>