var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('div',{staticClass:"form-group mt-4"},[_vm._m(0),_c('v-select-servicio',{class:{
            'is-invalid' : _vm.$v.remision.servicio.$error && _vm.$v.remision.servicio.$dirty
         },attrs:{"groups":[1],"mode":"object","validator":_vm.$v.remision.servicio},model:{value:(_vm.remision.servicio),callback:function ($$v) {_vm.$set(_vm.remision, "servicio", $$v)},expression:"remision.servicio"}}),(!_vm.$v.remision.servicio.required)?_c('span',{staticClass:"invalid-feedback"},[_vm._v(" Este campo es requerido ")]):_vm._e()],1),_c('div',{staticClass:"form-group"},[_vm._m(1),_c('v-select-eps',{class:{
            'is-invalid' : _vm.$v.eps.$error && _vm.$v.eps.$dirty
         },attrs:{"mode":"object"},model:{value:(_vm.eps),callback:function ($$v) {_vm.eps=$$v},expression:"eps"}}),(!_vm.$v.eps.required)?_c('span',{staticClass:"invalid-feedback"},[_vm._v(" Este campo es requerido ")]):_vm._e()],1),_c('div',{staticClass:"form-group"},[_vm._m(2),_c('div',{},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.remision.motivo),expression:"remision.motivo"}],staticClass:"form-control",class:{'is-invalid':_vm.$v.remision.motivo.$error && _vm.$v.remision.motivo.$dirty},domProps:{"value":(_vm.remision.motivo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.remision, "motivo", $event.target.value)}}})])]),_c('button',{staticClass:"btn btn-success btn-sm",on:{"click":function($event){return _vm.guardar()}}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" Guardar ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label"},[_c('i',{staticClass:"fas fa-poll-h fa-fw"}),_vm._v("Servicio al que se remite")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"small mb-1"},[_vm._v("Administradora (EPS) "),_c('small',[_vm._v("(*)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label"},[_c('i',{staticClass:"far fa-comment-alt fa-fw"}),_vm._v(" Motivo ")])
}]

export { render, staticRenderFns }