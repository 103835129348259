<template>
  <div>
    <div class="row no-gutters">
      <div v-for="item in intervenciones" :key="item.id" class="form-group col-12 pl-2 pr-2">
        <label class="mb-0">{{ item.intervencion }}</label>
        <textarea v-model="item.detalle" class="form-control form-control-sm" rows="5"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import intervencionService from "@/services/intervencionService";
import intervencionHistoriaService from "@/services/intervencionHistoriaService";
import Swal from "sweetalert2";
import Toast from "@/components/common/utilities/toast";
import {isEmpty} from "lodash";

export default {
  props : ['idHistoria', 'idTipoHistoria', 'usuario'],
  data(){
    return {
      intervenciones : []
    }
  },
  methods : {
    async cargarIntervenciones(){

      const response = await intervencionService.index({
        id_tipo_historia : this.idTipoHistoria,
      });

      this.intervenciones = response.data.map(x => {
        return {
          ...x,
          detalle : ''
        }
      });
    },
    async save(){
      try{

        const obj = {
          id_historia : this.idHistoria,
          intervenciones : this.intervenciones.filter(x=>x.detalle !== '')
        };

        this.LoaderSpinnerShow();

        await intervencionHistoriaService.store(obj);

        this.LoaderSpinnerHide();

        Toast.fire({
          icon: 'success',
          title: 'Datos guardados con exíto'
        });

      }catch (e) {
        console.log(e);
        this.LoaderSpinnerHide();
        Swal.fire('Ups', 'ha ocurrido un error al procesar la solicitud');
      }


    }
  },
  async created() {
    await this.cargarIntervenciones();

    const response = await intervencionHistoriaService.index({
      id_historia : this.idHistoria
    });

    if(!isEmpty(response.data)){
      response.data.forEach(x => {
        let i = this.intervenciones.findIndex(y=> y.id === x.id_intervencion);
        if(i !== -1) this.intervenciones[i].detalle = x.detalle;
      });
    }
  }
}
</script>

<style scoped>

</style>