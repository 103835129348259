<template>
  <div class="">
    <div class="card-header bg-light">
      Plan de Intervención
    </div>
    <div class="card-body">
      <div class="row no-gutters border-bottom py-2" v-for="(intervencion, i) in data" :key="`${i}_${intervencion.id}`">
        <div class="col-12">
          <h6 class="h6 text-uppercase d-block">{{intervencion.intervencion.intervencion}}</h6>
          <p class="small">
            {{intervencion.detalle}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props : ["idHistoria",'data'],
  name: "intervencionesShow",
}
</script>
