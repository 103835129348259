<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-stethoscope"> </i> Motivo de Consulta</span> </div>
            <div class="card-body" v-if="data">
                <div class="row">
                    <div class="col-md-4 text-black">
                        Motivo:
                    </div>
                    <div class="col-md-8">
                        {{data.motivo}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 text-black">
                        Descripción:
                    </div>
                    <div class="col-md-8">
                        {{data.descripcion}}
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    props: ['idHistoria','data'],
}
</script>

