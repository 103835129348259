<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-pills"> </i> Formulas</span> </div>
            <div class="card-body">
                <div class="alert alert-warning" v-if="!data.length">
                    No se han registrado formulas medicas...
                </div>
                <div class="table-responsive" v-for="form in data" :key="form.id">
                    <div class="mb-3">
                        <span class="text-info">Formula No. {{form.id}}</span>
                        <table
                            class="table table-borderless mb-0 table-sm table-services"
                        >
                            <thead class="border-bottom">
                            <tr class="small text-uppercase text-muted">
                                <th scope="col">Medicamento</th>
                                <th scope="col">Dosis</th>
                                <th scope="col">Cada</th>
                                <th scope="col">Duracion</th>
                                <th scope="col">Total</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr
                                    class="border-bottom"
                                    v-for="item in form.items" :key="item.id"
                                >
                                    <td>
                                        <div class="font-weight-bold">
                                            {{ item.servicio.codigo }}
                                            {{ item.servicio.descripcion }}
                                            <div class="small text-muted d-none d-md-block">
                                                F. farmaceutica: {{ item.presentation }}
                                            </div>
                                            <div class="small text-muted d-none d-md-block">
                                                Via admin : {{ item.route_of_administration }}
                                            </div>
                                            <div class="small text-muted d-none d-md-block">
                                                Indicaciones : {{ item.indications }}
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{ item.dosis }}</td>
                                    <td>{{ item.frecuencia }} {{item.unidad_frecuencia}}</td>
                                    <td>{{ item.duracion }} {{ item.unidad_duracion }}</td>
                                    <td>{{ item.total }} {{item.presentation}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    props: ['idHistoria','data'],
}
</script>

