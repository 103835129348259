<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-diagnoses"> </i> Diagnostico</span> </div>
            <div class="card-body">
                <div class="table-responsive">
                    <div >
                        <span class="text-info">Diagnostico No. {{data.id}}</span>
                        <table class="table table-bordered" >
                            <tr class="table-primary">
                                <th>Codigo</th>
                                <th>Descripcion</th>
                            </tr>
                            <tr v-for="item in data.items" :key="item.id">
                                <td>{{ item.diagnostico.ID_DIAG }}</td>
                                <td>{{ item.diagnostico.DESC_DIAG }}</td>
                            </tr>  
                                                        
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>

export default {
    props: ['idHistoria','data']
}
</script>

