<template>
    <main ref="resumen" id="resumen">
      <div class="card mt-2 shadow-none" v-if="isLoading">
        <div class="card-body">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="false" />
            <content-placeholders-text :lines="3" />
          </content-placeholders>
        </div>
      </div>
      <div v-else>
        <div class="py-2">
          <motivoConsultaShow :idHistoria="idHistoria" :data="resumen.motivo_consulta"></motivoConsultaShow>
        </div>
        <div class="py-2">
          <examenFisicoShow :idHistoria="idHistoria" :examenFisico="resumen.examen_fisico"></examenFisicoShow>
        </div>
        <div class="py-2" v-if="resumen && resumen.ordenes">
          <ordenClinicaShow :idHistoria="idHistoria" :data="resumen.ordenes"></ordenClinicaShow>
        </div>
        <div class="py-2" v-if="resumen && resumen.formulas">
          <formulaMedicaShow :idHistoria="idHistoria" :data="resumen.formulas"></formulaMedicaShow>
        </div>
        <div class="py-2" v-if="resumen && resumen.impresion_diagnostica">
          <impresionDxShow :idHistoria="idHistoria" :data="resumen.impresion_diagnostica"></impresionDxShow>
        </div>
        <div class="py-2" v-if="resumen && resumen.urgencia_revision">
          <revisionSistemasShow :idHistoria="idHistoria" :data="resumen.urgencia_revision"></revisionSistemasShow>
        </div>
        <div class="py-2" v-if="resumen && resumen.usuario">
          <vacunasShow :idHistoria="idHistoria" :data="resumen.usuario"></vacunasShow>
        </div>
        <div class="py-2">
          <showRespuestaFormulario :idHistoria="idHistoria" :codigo="'EV_R'"></showRespuestaFormulario>
        </div>
        <div class="py-2" v-if="resumen && resumen.reporte_examenes">
            <reporteExamenesShow :idHistoria="idHistoria" :data="resumen.reporte_examenes"></reporteExamenesShow>
        </div> 
        <div class="py-2"  v-if="resumen && resumen.intervenciones">
            <intervencionesShow :idHistoria="idHistoria" :data="resumen.intervenciones"></intervencionesShow>
        </div>
        <div class="py-2" v-if="resumen && resumen.recomendaciones">
          <recomendacionesShow :idHistoria="idHistoria" :data="resumen.recomendaciones"></recomendacionesShow>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  import motivoConsultaShow from "../../base/motivoConsulta/motivoConsultaShow";
  import ordenClinicaShow from "../../base/orden/ordenClinicaShow";
  import formulaMedicaShow from "../../base/formulaMedica/formulaMedicaShow";
  import impresionDxShow from "../../base/impresionDX/impresionDxShow";
  import revisionSistemasShow from "../../base/baseUrgencia/revisionShow";
  import historiaClinicaService from "../../../../services/historiaClinicaService";
  import recomendacionesShow from "../../base/recomendaciones/recomendacionesShow";
import examenFisicoShow from "../../base/examenFisico/examenFisicoShow";
import vacunasShow from "../../base/baseVacunas/index/vacunasShow";
import reporteExamenesShow from "../../base/baseReporteExamenesAdolescente/reporteExamenesShow";
import intervencionesShow from "../../base/intervenciones/intervencionesShow";
import showRespuestaFormulario from "../../base/baseRespuestaFormularios/showRespuestas";

  export default {
    props: ["idHistoria", "idUsuario", "usuario"],
    components: {
      motivoConsultaShow,
      ordenClinicaShow,
      formulaMedicaShow,
      impresionDxShow,
      revisionSistemasShow,
      recomendacionesShow,examenFisicoShow,vacunasShow,reporteExamenesShow,intervencionesShow,showRespuestaFormulario
    },
    data() {
      return {
        resumen: {},
        isLoading: true
      };
    },
  
    async created() {
      this.isLoading = true;
      const response = await historiaClinicaService.resumenHc(this.idHistoria);
      this.resumen = response.data;
      this.isLoading = false;
  
    },
  };
  </script>
  
  <style scoped>
  .resumen-container {
    height: 300px;
    overflow-y: scroll;
  }
  </style>
  