<template>
    <main>
        <div class="card shadow-none border-0">
            <div class="card-header bg-light "><span><i class="fa fa-first-aid"> </i> REVISIÓN POR SISTEMAS</span> </div>
            <div class="card-body">
                <p>{{ data.revision }}</p>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    props: ['idHistoria', 'finalizada','data']
}
</script>

