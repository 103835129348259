<template>
  <div class="card shadow-none border-0">
    <div class="card-header bg-light "><i class="fa fa-list fa-fw"> </i>Recomendaciones</div>
    <div class="card-body" v-if="false">
      <div class="row no-gutters border-bottom mb-1" v-for="item in data" :key="item.id" >
       <!--  <div class="col-12 py-2">
          <blockquote class="small m-0 mr-4">{{item.recomendacion.recomendacion}}</blockquote>
        </div> -->
      </div>
    </div>
    <div class="card-body" v-for="item in data" :key="item.id" >{{item.recomendacion}}</div>
  </div>
</template>

<script>
export default {
  props : ['idHistoria','data'],
  name: "recomendacionesShow",
}
</script>