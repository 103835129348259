<template>
  <main>
    <div class="form-row mb-2 mt-2 border-bottom">
      <div class="d-flex">
        <button class="btn btn-light text-primary justify-content-end" @click="openModalHistoyResults()"><i class="fa fa-eye fa-sw"></i>Ver todo el Historial de resultados</button>
      </div>
      <modalHistoryResults :idUsuario="idUsuario" ref="modalResults"></modalHistoryResults>
      <table class="table table-sm table-bordered mt-3">
        <tr class="bg-light">
          <th>Examen/Procedimiento</th>
          <th>Fecha</th>
          <th>Resultado</th>
        </tr>
        <tr v-for="item in procedimientos" :key="item.id">
          <td>
            {{ item.descripcion }}
          </td>
          <td>
            <input type="date" v-model="item.fecha" class="form-control form-control-sm">
          </td>
          <td>
            <input v-if="item.tipo === 'number'" :type="item.tipo" v-model="item.resultado"
              class="form-control form-control-sm">
            <textarea v-else v-model="item.resultado" class="form-control form-control-sm"></textarea>
          </td>
        </tr>
      </table>
    </div>
    <div class="form-row mt-2">
      <div class="col-12 d-flex justify-content-end">
        <button class="btn btn-success btn-sm" @click="save()"><i class="fa fa-save fa-fw"></i>Guardar</button>
      </div>
    </div>
  </main>
</template>

<script>
import historiaControlExamenService from "../../../../services/historiaControlExamenService";
import Swal from "sweetalert2";
import examenProcedimientoService from '../../../../services/examenProcedimientoService';
import { isEmpty } from "lodash";

import { required } from "vuelidate/lib/validators";
import Toast from "@/components/common/utilities/toast";
import modalHistoryResults from './modalHistoryResults';

export default {
  props: ['idHistoria', 'finalizada', 'idUsuario', 'idTipoHistoria', 'usuario'],
  components:{modalHistoryResults},
  data() {
    return {
      procedimientos: [],
      items_procedimientos: [],
      item_procedimiento: {
        procedimiento: {},
        resultado: '',
        fecha: ''
      },
      embarazo_actual: {},
      fecha_resultados: ''
    };
  },
  validations() {
    return {
      item_procedimiento: {
        procedimiento: { required },
        fecha:{required},
        resultado: { required },
      },

      //fecha_resultados: { required },
    }
  },
  methods: {
    async cargarProcedimientos() {

      const response = await examenProcedimientoService.index({
        fecha_nacimiento: this.usuario.fecha_nacimiento,
        id_tipo_historia: this.idTipoHistoria,
        sexo: this.usuario.sexo
      });

      this.procedimientos = response.data.map(x => {
        return {
          ...x,
          fecha: '',
          resultado: '',
        }
      });

    },
    async save() {
      try {
        /* this.$v.fecha_resultados.$touch();
        if (this.$v.fecha_resultados.$invalid) {
          return
        } */
        //if (!this.procedimientos.filter(x => x.resultado !== '').length) return;
        if(!this.procedimientos.filter(x => x.resultado !== '' && x.fecha !== '').length) return;

        let historia_control = {
          id_historia: this.idHistoria,
          fecha: this.fecha_resultados,
          procedimientos: this.procedimientos.filter(x => x.resultado !== '' && x.fecha !== '')
        };

        this.LoaderSpinnerShow();

        await historiaControlExamenService.store(historia_control);

        this.LoaderSpinnerHide();

        Toast.fire({
          icon: 'success',
          title: 'Datos guardados con exito'
        });

      } catch (e) {
        console.error(e);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: 'error',
          title: 'ha ocurrido un error al procesar la solicitud'
        });
      }
    },
    limpiarCampos() {
      this.item_procedimiento.resultado = '',
        this.item_procedimiento.procedimiento = {}
    },
    async eliminar(id) {
      try {
        this.LoaderSpinnerShow();
        await historiaControlExamenService.delete(id);
        this.LoaderSpinnerHide();
        Swal.fire('Datos Eliminados con exito', '', 'success');
        let i = this.items_procedimientos.findIndex(x => x.id === id);
        this.items_procedimientos.splice(i, 1);
      } catch (e) {
        console.error(e);
        this.LoaderSpinnerHide();
        Swal.fire("Ups!", "ha ocurrido un error al procesar la solicitud", "error");
      }
    },
    openModalHistoyResults(){
      this.$refs.modalResults.openModal();
    }
  },
  async created() {
    await this.cargarProcedimientos();
    const response = await historiaControlExamenService.showByHc(this.idHistoria);

    if (!isEmpty(response.data)) {

      response.data.forEach((x) => {
        let index = this.procedimientos.findIndex(y => parseInt(y.id) === parseInt(x.examen.id));
        if (index !== -1) {
          this.procedimientos[index].resultado = x.resultado;
          this.procedimientos[index].fecha = x.fecha;
          //this.fecha_resultados = x.fecha;
        }
      });

    }else{
      const response_history_results=await historiaControlExamenService.getHistory(this.idUsuario)
      response_history_results.data.forEach((x) => {
        let index = this.procedimientos.findIndex(y => parseInt(y.id) === parseInt(x.examen.id));
        if (index !== -1) {
          this.procedimientos[index].resultado = x.resultado;
          this.procedimientos[index].fecha = x.fecha;
          //this.fecha_resultados = x.fecha;
        }
      });
    }
  },
}
</script>