<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-pills"> </i> Examenes</span> </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <tr >
                            <th>Procedimiento</th>
                            <th>Fecha</th>
                            <th>Resultado</th>
                        </tr>
                        <tr v-for="item in data" :key="item.id" >
                            <td>{{item.examen.servicio.descripcion}}</td>
                            <td>{{ item.fecha }}</td>
                            <td>{{ item.resultado }}</td>
                        </tr>                                
                    </table>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    props: ['idHistoria','finalizada','idUsuario','data']
}
</script>