<template>
    <main>
        
        <!-- Modal -->
        <div class="modal fade" id="historyResults" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Histórico de resultados</h5>
                        <button class="btn btn-transparent text-bold" type="button" @click="closeModal()" aria-label="Close"><i class="fa fa-times-circle fa-fw"></i></button>
                    </div>
                    <div class="modal-body">
                        <data-grid :data="pagination" @changePage="changePage($event)"
                        @perPageChange="perPageChange($event)" @search="search($event)" :searchBox="false">
                        <template #grid>
                            <div class="card mb-4 shadow-none" v-for="(item,index) in pagination.data " :key="index">
                            <div class="card-header text-bold">{{ item.id_historia }} <span class="badge badge-light font-weight-bolder">{{ item.name }}</span></div>
                            <div class="list-group list-group-flush small">
                                <span class="list-group-item" v-for="examen in item.examenes" :key="examen.id">
                                    <span class="font-weight-bolder">
                                        {{ examen.examen.descripcion }} 
                                    </span>
                                    <ul>
                                        <li><span>{{ examen.fecha | formatDate}}</span></li>
                                        <li><span>{{ examen.resultado }}</span></li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                        </template>
                    </data-grid>
                        
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-light" type="button" @click="closeModal()"><i class="fa fa-times-circle fa-fw"></i>Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import historiaControlExamenService from "../../../../services/historiaControlExamenService";
import $ from 'jquery';
import Toast from '../../../../components/common/utilities/toast';
import DataGrid from "../../../../components/common/utilities/DataGrid/DataGrid";
export default{
    props:['idUsuario'],
    components:{DataGrid},
    data(){
        return {
            results:[],
            pagination: {
                data: [],
                current_page: 1,
                page: 1,
                per_page: 5,
                search: '',
                total: '',
                params: {
                    id_user: "",
                    fecha: "",
                },
            },
        }
    },
    methods:{
        async getResults(){
            
            try {
                this.LoaderSpinnerShow();

                const response = (await historiaControlExamenService.getAllHistory(this.pagination.search, this.pagination, this.idUsuario))?.data;

                this.pagination.data = response.data.map(x => {
                    return {
                        ...x
                    }
                });
                this.pagination.current_page = response.current_page;
                this.pagination.total = response.total;
                this.LoaderSpinnerHide();
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        openModal(){
            this.$nextTick( () => {
                $("#historyResults").modal("show");
                this.getResults();
            });
        },
        closeModal(){
            $("#historyResults").modal("hide");
        },
        changePage(page) {
            this.pagination.page = page;
            this.getResults();
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.getResults();
        },
        search(query) {
            this.pagination.search = query;
            this.pagination.page = 1;
            this.getResults();
        },
    },
    
}
</script>