<template>
    <div class="modal fade" id="modalOrden" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Ordenes Clinicas</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="alert alert-warning mt-2" v-if="!ordenes.length">
                        No se han cargado ordenes...
                    </div>
                    <div v-else class="card mt-2 border-gray-500 shadow-none" v-for="item in ordenes" :key="item.id">
                        <div class="card-header bg-light">
                            <div class="row">
                                <div class="col-4 ">
                                    Orden No. {{item.id}} 
                                </div>
                                <div class="col-8 d-flex justify-content-end">
                                    <span class="text-danger mx-2">{{item.user.name}}</span> ({{item.created_at | formatDateTime}})
                                </div>
                            </div>
                        </div>
                        <div class="card-body" >
                            <div class="row">
                                <div class="col-md-8 col-sm-12 col-lg-12 font-weight-bold" v-for="procedimiento in item.items" :key="procedimiento.id">
                                    {{ procedimiento.servicio.codigo }}
                                    {{ procedimiento.servicio.descripcion }}
                                    <div class="small text-muted d-none d-md-block">
                                        Observación: {{ procedimiento.observacion }}
                                    </div>
                                    <hr>
                                </div>
                                <div class="col-md-8 col-sm-12 col-lg-12">
                                    Observaciones Generales: <span class="text-primary">{{item.observaciones}}</span>
                                </div>
                            </div>
                            <div class="btn-group shadow-sm float-right" role="group" >
                                <button @click="verOrden(item.id)" class="btn btn-outline-primary btn-xs" >
                                    <i class="fa fa-eye fa-fw"></i> Ver orden
                                </button>
                                <button @click="imprimir(item.id)" class="btn btn-outline-success btn-xs" >
                                    <i class="fa fa-print fa-fw"></i> Imprimir
                                </button>
                                <button v-if="item.id_user===user.id" @click="eliminarOrden(item.id)" class="btn btn-outline-danger btn-xs" >
                                    <i class="fa fa-trash-alt"></i> Eliminar orden
                                </button>
                            </div>
                            <div class="mt-2" v-for="orden in item.ordenes_trabajo" :key="orden.id">
                                <button v-if="orden.resultado!=null" @click="resultLab(orden.resultado.id)" class="btn btn-secondary btn-sm" >
                                    <i class="fa fa-flask fa-fw"></i> Ver resultados
                                </button>
                               </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light btn-sm" data-dismiss="modal"><i class="far fa-times-circle fa-fw"></i> Cerrar</button>
                </div>
                </div>
            </div>
        </div>
</template>
<script>
import ordenClinicaService from "../../../../services/ordenClinicaService";
import $ from 'jquery';
import Toast from '../../../../components/common/utilities/toast';
import historiaClinicaService from "../../../../services/historiaClinicaService";
import Swal from 'sweetalert2';
import ordenTrabajoService from "../../../../services/ordenTrabajoService";
export default {
    props: ['ordenes','usuario'],
    methods:{
        async eliminarOrden(id) {
            const result = await Swal.fire({
                title :  "¿Quiere eliminar esta orden?",
                text : "Esta opción no es reversible",
                showCancelButton : true,
                showConfirmButton : true,
                confirmButtonText : 'Sí, Eliminar',
                cancelButtonText : 'No',
                icon : "question",
                showLoaderOnConfirm : true,
                preConfirm: async () => {
                    try{
                        this.LoaderSpinnerShow();
                        await ordenClinicaService.delete(id);
                        this.LoaderSpinnerHide();
                    }catch (e) {
                        console.error(e);
                        this.LoaderSpinnerHide();
                        Swal.showValidationMessage('ha ocurrido un error al procesar la solicitud');
                    }
                },
            });

            if(result.isConfirmed){
                Toast.fire({
                    icon: 'success',
                    title: 'Datos eliminados con exito'
                });
                $('#modalOrden').modal('hide');
            } 
        },
        async verOrden(id) {
            $('#modalOrden').modal('hide');
            this.$router.push({name:'orden.clinica',params:{'id_orden':id,'id_usuario':this.usuario.id}});
        },
        imprimir(id_orden){
            window.open(historiaClinicaService.orden(id_orden));
        },
        resultLab(id) {
            window.open(ordenTrabajoService.imprimir(id));
        },
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    }
}
</script>
